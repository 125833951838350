import axios from "axios";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import useHeaders from "../../../hooks/useHeaders";

import bannerLandingDestacatos from "../../../img/promos/landing-destacados/banner-landing-destacados.webp";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import ToggleButton from "../../ToggleButton";

const ExplorerDestacados = ({
    vistaAdministrador,
    setVistaAdministrador,
    setRefreshHouses,
}) => {
    const [fields, setFields] = useState([]);
    const [input, setInput] = useState("");
    const { tipo_usuario } = useSelector((state) => state.authReducer);
    const { headers } = useHeaders();
    const debounceInput = useRef(null);
    const [fieldSelect, setFieldSelect] = useState(null);

    const handleBlur = () => {
        setTimeout(() => {
            setFields([]);
            setInput("");
        }, 200);
    };

    return (
        <div
            className={`${
                tipo_usuario !== null && tipo_usuario <= 2
                    ? "mt-[8rem]"
                    : "mt-[6rem]"
            }`}
        >
            {/*  {fieldSelect && (
                <HouseModal
                    fieldSelect={fieldSelect}
                    setRefreshHouses={setRefreshHouses}
                    setShowModal={setFieldSelect}
                    headers={headers}
                />
            )} */}
            <div
                className={`w-full bg-center 
                bg-cover flex flex-col bg-white text-center`}
            >
                <div className="h-full text-white swiper-san-valentin transition-all ease-out duration-[200ms] p-4 w-full  xl:w-[70%]  mx-auto mt-6">
                    <Swiper
                        // modules={[Navigation]}
                        className="h-[12rem] sm:h-[20rem] rounded-t-xl lg:h-[23rem] 2xl:h-[30rem] bg-gradient-to-bl from-white to-blue-bluecorner select-none flex justify-center items-center"
                        // grabCursor
                        slidesPerView={1}
                        centeredSlides
                        speed={1000}
                        // loop
                        // navigation
                    >
                        <SwiperSlide className="transition-all duration-200 ease-out rounded-lg">
                            <div className="relative w-full h-full transition-all duration-300 bg-gradient-to-t from-white to-blue-400">
                                <img
                                    src={bannerLandingDestacatos}
                                    className="w-full h-full mx-auto transition-all duration-200 ease-out rounded-lg"
                                    alt={`Banner landing destacados`}
                                />
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>

                {(tipo_usuario === 1 || tipo_usuario === 2) && (
                    <div className="flex justify-end px-8 mt-3 md:px-6 xl:px-32 2xl:px-64">
                        <p>
                            <span className="text-[#053465] font-medium mr-2">
                                Vista administrador
                            </span>
                        </p>
                        <div>
                            <ToggleButton
                                isToggled={false}
                                onChangeToggle={(e) => setVistaAdministrador(e)}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ExplorerDestacados;
