import React, { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { useSelector } from "react-redux";
import axios from "axios";
import dividirFechaDatePicker from "../../helpers/dividirFechaDatePicker";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";

export const FormularioReservaCasa = ({
    habilitarModalFormulario,
    setHabilitarModalFormulario,
    id_casa,
    nombre_casa,
}) => {
    registerLocale("es", es);
    const authUser = useSelector((state) => state.authReducer);
    const navigate = useNavigate();
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };
    const [datosCliente, setDatosCliente] = useState(null);
    const [cotizar, setCotizar] = useState(false);
    const [cargando, setCargando] = useState(false);

    const [input, setInput] = useState({
        nombre: "",
        apellido: "",
        correo: "",
        personas: "",
        fecha_inicio: "",
        fecha_final: "",
        nota: "",
        celular: "",
    });

    const enviar = async () => {
        if (
            input.nombre === "" ||
            input.apellido === "" ||
            input.correo === "" ||
            input.personas === "" ||
            input.fecha_inicio === "" ||
            input.fecha_final === "" ||
            input.celular === ""
        ) {
            Swal.fire({
                icon: "error",
                title: "Todos los campos son requeridos",
                showConfirmButton: false,
                timer: 1500,
            });
            return;
        }
        setCargando(true);

        const fechaInicioFormateada = new Date(
            input.fecha_inicio
        ).toLocaleDateString("es-ES");
        const fechaFinalFormateada = new Date(
            input.fecha_final
        ).toLocaleDateString("es-ES");

        const { data } = await axios.post(
            `${process.env.REACT_APP_PROXY}/casa/enviarCotizacionFormulario`,
            {
                nombre: `${input.nombre} ${input.apellido}`,
                correo: input.correo,
                personas: input.personas,
                inicio: fechaInicioFormateada,
                final: fechaFinalFormateada,
                casa: nombre_casa,
                celular: input.celular,
            },
            { headers }
        );
        setCargando(false);

        const mensaje = `
Hola Estoy interesad@ en reservar *${nombre_casa}*

*Mis datos son*:
*Nombre*: ${input.nombre}
*Apellido*: ${input.apellido}
*Correo*: ${input.correo}
*Cantidad de personas*: ${input.personas}
*Fecha de inicio*: ${fechaInicioFormateada}
*Fecha de salida*: ${fechaFinalFormateada}

        `;

        const mensajeCodificado = encodeURIComponent(mensaje);

        // const numeroWhatsApp = "554598373045";
        const numeroWhatsApp = "51941110270";

        const urlWhatsApp = `https://wa.me/${numeroWhatsApp}?text=${mensajeCodificado}`;
        window.open(urlWhatsApp, "_blank");
        return;
    };

    return (
        <div>
            <div>
                <div className="py-4 border-2 border-[#053465] rounded-lg ">
                    <p className="text-xl font-semibold text-center sm:text-2xl">
                        Datos para la reserva
                    </p>
                    <div className="relative grid w-10/12 gap-4 mx-auto mt-2 xs:grid-cols-2">
                        <div className="flex flex-col ">
                            <label className="mb-1 text-sm tracking-wide text-left text-gray-600">
                                Nombre:
                            </label>
                            <input
                                autoComplete="off"
                                type="text"
                                name="nombre"
                                onChange={(e) =>
                                    setInput({
                                        ...input,
                                        nombre: e.target.value,
                                    })
                                }
                                value={input.nombre}
                                className="w-full py-2 pl-4 pr-4 text-sm border border-[#e5e7eb] rounded-lg focus:outline-none disabled:bg-slate-50  "
                                placeholder="Nombre"
                            />
                        </div>
                        <div className="flex flex-col ">
                            <label className="mb-1 text-sm tracking-wide text-left text-gray-600">
                                Apellido:
                            </label>
                            <input
                                autoComplete="off"
                                type="text"
                                name="apellido"
                                onChange={(e) =>
                                    setInput({
                                        ...input,
                                        apellido: e.target.value,
                                    })
                                }
                                value={input.apellido}
                                className="w-full py-2 pl-4 pr-4 text-sm border border-[#e5e7eb] rounded-lg focus:outline-none disabled:bg-slate-50 "
                                placeholder="Apellido"
                            />
                        </div>
                    </div>
                    <div className="relative grid w-10/12 gap-4 mx-auto mt-2 xs:grid-cols-2">
                        <div className="flex flex-col ">
                            <label className="mb-1 text-sm tracking-wide text-left text-gray-600">
                                Personas:
                            </label>
                            <input
                                autoComplete="off"
                                type="number"
                                name="personas"
                                onChange={(e) =>
                                    setInput({
                                        ...input,
                                        personas: e.target.value,
                                    })
                                }
                                value={input.personas}
                                className="w-full py-2 pl-4 pr-4 text-sm border border-[#e5e7eb] rounded-lg focus:outline-none disabled:bg-slate-50 "
                                placeholder="Cantidad de personas"
                            />
                        </div>
                        <div className="flex flex-col ">
                            <label className="mb-1 text-sm tracking-wide text-left text-gray-600">
                                Correo:
                            </label>
                            <input
                                autoComplete="off"
                                type="text"
                                name="correo"
                                onChange={(e) =>
                                    setInput({
                                        ...input,
                                        correo: e.target.value,
                                    })
                                }
                                value={input.correo}
                                className="w-full py-2 pl-4 pr-4 text-sm border border-[#e5e7eb] rounded-lg focus:outline-none disabled:bg-slate-50 "
                                placeholder="Correo"
                            />
                        </div>
                    </div>
                    <div className="relative grid w-10/12 gap-4 mx-auto mt-2 ">
                        <div className="flex flex-col ">
                            <label className="mb-1 text-sm tracking-wide text-left text-gray-600">
                                Celular:
                            </label>
                            <input
                                autoComplete="off"
                                type="number"
                                name="personas"
                                onChange={(e) =>
                                    setInput({
                                        ...input,
                                        celular: e.target.value,
                                    })
                                }
                                value={input.celular}
                                className="w-full py-2 pl-4 pr-4 text-sm border border-[#e5e7eb] rounded-lg focus:outline-none disabled:bg-slate-50 "
                                placeholder="Celular"
                            />
                        </div>
                    </div>
                    <div className="relative grid w-10/12 gap-4 mx-auto mt-2 xs:grid-cols-2">
                        <div className="flex flex-col ">
                            <label className="mb-1 text-sm tracking-wide text-left text-gray-600">
                                Fecha de inicio:
                            </label>
                            <DatePicker
                                className=" w-full p-2 mx-auto rounded-lg cursor-pointer  border-[#e5e7eb] border"
                                placeholderText="Fecha de entrada"
                                locale="es"
                                autoComplete="off"
                                dateFormat="dd/MM/yyyy"
                                selected={
                                    input.fecha_inicio !== ""
                                        ? new Date(
                                              dividirFechaDatePicker(
                                                  input.fecha_inicio
                                              )[0],
                                              dividirFechaDatePicker(
                                                  input.fecha_inicio
                                              )[1] - 1,
                                              dividirFechaDatePicker(
                                                  input.fecha_inicio
                                              )[2]
                                          )
                                        : input.fecha_inicio
                                }
                                onChange={(e) =>
                                    setInput({
                                        ...input,
                                        fecha_inicio: `${e.getFullYear()}-${`${
                                            e.getMonth() + 1
                                        }`.padStart(
                                            2,
                                            "0"
                                        )}-${`${e.getDate()}`.padStart(
                                            2,
                                            "0"
                                        )}`,
                                    })
                                }
                            />
                        </div>
                        <div className="flex flex-col ">
                            <label className="mb-1 text-sm tracking-wide text-left text-gray-600">
                                Fecha de salida:
                            </label>
                            <DatePicker
                                className="w-full p-2 mx-auto rounded-lg cursor-pointer  border-[#e5e7eb] border "
                                placeholderText="Fecha de salida"
                                locale="es"
                                autoComplete="off"
                                dateFormat="dd/MM/yyyy"
                                selected={
                                    input.fecha_final !== ""
                                        ? new Date(
                                              dividirFechaDatePicker(
                                                  input.fecha_final
                                              )[0],
                                              dividirFechaDatePicker(
                                                  input.fecha_final
                                              )[1] - 1,
                                              dividirFechaDatePicker(
                                                  input.fecha_final
                                              )[2]
                                          )
                                        : input.fecha_final
                                }
                                onChange={(e) =>
                                    setInput({
                                        ...input,
                                        fecha_final: `${e.getFullYear()}-${`${
                                            e.getMonth() + 1
                                        }`.padStart(
                                            2,
                                            "0"
                                        )}-${`${e.getDate()}`.padStart(
                                            2,
                                            "0"
                                        )}`,
                                    })
                                }
                            />
                        </div>
                    </div>
                    {/* <div className="relative grid w-10/12 grid-cols-1 gap-4 mx-auto mt-2">
                        <div className="flex flex-col ">
                            <label className="mb-1 text-sm tracking-wide text-left text-gray-600">
                                Comentario:
                            </label>
                            <textarea
                                autoComplete="off"
                                type="text"
                                name="nota"
                                onChange={(e) =>
                                    setInput({
                                        ...input,
                                        nota: e.target.value,
                                    })
                                }
                                value={input.nota}
                                className="w-full py-2 pl-4 pr-4 text-sm border border-[#e5e7eb] rounded-lg focus:outline-none "
                                placeholder="Comentario"
                            />
                        </div>
                    </div> */}
                    <div className="relative grid w-10/12 grid-cols-1 gap-4 mx-auto mt-4 xs:mt-2">
                        <button
                            disabled={cargando}
                            onClick={() => enviar()}
                            className={`mx-2 text-center`}>
                            <p
                                className={` font-semibold bg-blue-500 rounded-lg min-w-max p-3 text-white w-40 hover:bg-blue-600 mx-auto disabled:bg-blue-300`}>
                                {cargando ? "Enviando..." : "Cotizar"}
                            </p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
