import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import ToggleButton from "../../ToggleButton";

const EditarTipoReserva = ({
    producto,
    setProducto,
    setModal,
    tipo_producto,
}) => {
    const [toggleButton, setToggleButton] = useState(
        producto.data?.tipo_reserva === "calendario" ? true : false
    );
    const [loading, setLoading] = useState(false);
    const token = localStorage.getItem("token");
    const headers = {
        authorization: token,
    };

    const handleGuardar = async () => {
        setLoading(true);
        try {
            const json = await axios.put(
                `${process.env.REACT_APP_PROXY}/casa/updateTipoReserva/${producto.data.id}`,
                {
                    tipo_reserva: toggleButton ? "calendario" : "formulario",
                },

                { headers }
            );

            if (json.data.message === "actualizado con exito") {
                setProducto({
                    ...producto,
                    data: {
                        ...producto.data,
                        tipo_reserva: toggleButton
                            ? "calendario"
                            : "formulario",
                    },
                });
                setLoading(false);
                setModal(false);
                Swal.fire({
                    icon: "success",
                    title: "Tipo Reserva editado",
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
        } catch (error) {
            setLoading(false);

            Swal.fire({
                icon: "error",
                title: "Error al editar Tipo Reserva",
                showConfirmButton: false,
                timer: 1500,
            });
        }
    };

    return (
        <div className="p-4 border rounded-lg">
            <div>
                <div>
                    <div className="flex items-center justify-between w-full">
                        <h1>Tipo Reserva</h1>

                        <div className="flex items-center gap-2">
                            <p className="">Formulario</p>

                            <ToggleButton
                                isToggled={toggleButton}
                                onChangeToggle={(e) => setToggleButton(e)}
                            />
                            <p className="">Calendario</p>
                        </div>
                    </div>
                    <div></div>
                </div>
            </div>
            <div className="flex justify-end">
                <button
                    disabled={loading}
                    onClick={() => handleGuardar()}
                    className="p-3 my-6 border rounded-lg border-blue-bluecorner text-blue-bluecorner px-7">
                    {loading ? "Cargando..." : "Guardar"}
                </button>
            </div>
        </div>
    );
};

export default EditarTipoReserva;
