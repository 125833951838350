import React, { useState } from "react";
import Footer from "../../Footer";
import NavBar from "../../NavBar";
import AllCards from "./AllCards";

import ExplorerDestacados from "./ExplorerDestacados";

const LandingDestacados = () => {
    const [vistaAdministrador, setVistaAdministrador] = useState(false);
    const [refreshHouses, setRefreshHouses] = useState(false);

    return (
        <div>
            <div className="">
                <NavBar />
                <ExplorerDestacados
                    vistaAdministrador={vistaAdministrador}
                    setVistaAdministrador={setVistaAdministrador}
                    setRefreshHouses={setRefreshHouses}
                />
                <AllCards
                    vistaAdministrador={vistaAdministrador}
                    refreshHouses={refreshHouses}
                    setRefreshHouses={setRefreshHouses}
                />
            </div>
            <Footer messageWhatsapp={""} />
        </div>
    );
};

export default LandingDestacados;
